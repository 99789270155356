.Letter {
    --green: #2ECC40;
    --yellow: #FFDC00;
    --grey: #DDDDDD;

    display: flex;
    flex-direction: column;
    aspect-ratio: 1;
    overflow: hidden;
}

.Letter__green {
    background: var(--green);
}
.Letter__yellow {
    background: var(--yellow);
}
.Letter__grey {
    background: var(--grey);
}

.Letter-input {
    background: transparent;
    text-align: center;
    border: none;
    appearance: none;
    height: 75px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;
}

.Letter-toggle {
    height: 25px;
    display: block;
    width: 100%;
    border: none;
    appearance: none;
    font-weight: bold;
    color: white;
    background: rgba(0, 0, 0, 0.3);
}
