.ExcludedLetters {
    margin-bottom: 2em;
    max-width: 500px;
}

.ExcludedLetter {
    border: none;
    background: #AAAAAA;
    font-size: 15px;
    width: 25px;
    height: 45px;
    margin: 2px;
    border-radius: 3px;
    appearance: none;
    color: white;
    text-align: center;
    line-height: 45px;
    padding: 0;
}

.ExcludedLetter-active {
    background: #FF4136;
}
